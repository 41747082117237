<template>
  <div>
    <div class="news_banner">
      <img :src="banner[0].bannerImg" alt="" width="100%" />
    </div>
    <el-row :gutter="20" class="title">
      <el-col :span="16" :offset="0">
        <div style="overflow: hidden">
          <div class="gs-0">{{ list.mainTitle }}</div>
          <div class="gs-1">
            <img :src="list.img" alt="" width="100%" />
          </div>
          <div class="gs-2">
            <div v-html="list.mainContent"></div>
            <div class="gs-0">{{ list.subTitle }}</div>
            <div v-html="list.subContent"></div>
            <!-- 锐思智芯<span>®</span>是全球新一代视觉传感器技术革命的引领者。<br />
            <br />
            公司创立于2019年，在深圳、北京、苏黎世、南京设有研发团队。基于独创的
            Hybrid
            Vision<span>®</span>融合视觉技术，锐思智芯<span>®</span>突破性地研发了ALPIX<span>®</span>系列融合式视觉传感芯片，为智能手机、消费电子、智能安防、智能汽车领域提供一体化智能视觉解决方案，持续赋能视觉Al生态。<br />
            <br />
            锐思智芯<span>®</span>整合算法、软件、硬件、芯片研发经验和能力，核心技术专家全部来自全球顶尖机构。团队成员在高端视觉传感器领域拥有超过16年的技术积累，在事件驱动传感器领域拥有超过8年研究经验。<br />
            <div class="gs-0">愿景 | 使命 | 价值观</div>
            企业愿景：引领图像变革，拓展视觉疆界。<br />
            企业使命：激发、汇聚视觉传感领域的创新灵感,以卓越产品与方案赋能视觉生态，发现与记录世界的美与善。<br />
            企业价值观：创新，高效，真诚，务实。<br />
            企业口号：锐于思，智在芯。<br /> -->
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // title: "关于", // set a title
    meta: [
      {
        // set meta
        name: "keyWords",
        content: "我是keyWords关键字",
      },
      {
        name: "description",
        content: "我是description描述",
      },
    ],
    link: [
      {
        // set link
        rel: "asstes",
        href: "www.baidu.com",
      },
    ],
  },
  data() {
    return {
      banner: [
        {
          bannerImg: "",
        },
      ],
      list: "",
    };
  },
  computed:{
	  language(){
		  return this.$store.state.Language
	  }
  },
  watch:{
	  language(newVal,oldVal){
      window.document.title = this.$t('about.title')
		  this.getBanner();
		  this.getlist();
		  this.getBanner()
	  }
  },
  mounted() {},
  created() {
    window.document.title = this.$t('about.title')
    this.getBanner();
    this.getlist();
  },
  methods: {
    getBanner() {
	  let language = this.$store.state.Language
      this.$http.get("/appBanner/getBannerList?spaceId=13&language="+language).then((res) => {
        this.banner = res.data;
        console.log(this.banner);
      });
    },
    getlist() {
	  let language = this.$store.state.Language
      this.$http.get("about/get?language="+language).then((res) => {
		console.log(res)
        this.list = res.data;
        console.log(this.list);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gs-0 {
  color: #000;
  width: 100%;
  font-size: 28px;
  // color: rgb(35, 80, 199);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: 'Source Han Sans Light','Source Han Sans', 'Source Han Sans Unicode', Geneva, Verdana, sans-serif !important;
}
.gs-1 {
  display: inline-block;
  width: 100%;
  // padding-left: 2%;
  margin-top: 35px;
  margin-bottom: 40px;
}

.gs-2 {
  display: block;
  width: 100%;
  padding: 0.5%;
  padding-top: 0;
  line-height: 35px;
  float: right;
  box-sizing: border-box;
  // padding-left: 3%;
  margin-bottom: 50px;
  color: #000;

  span {
    display: inline-block;
    // font-size: 12px;
    transform: scale(0.7, 0.7);
    position: relative;
    top: -3px;
    left: 1px;
    margin-right: 6px;
  }
}
.news_banner {
  // background: url(../assets/images/cptou.jpg) no-repeat;
  // background-size: 100% 100%;
  // height: 100%;
  // display: flex;
  // justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;
  p {
    padding-left: 100px;
  }
}
// .aboutUs {
//   // flex-direction: column;
//   // align-items: center;
// }
.title {
  // s

  // height: 480px;
  display: flex;
  justify-content: center;
  // width: 100%;
  p {
    padding-left: 100px;
  }
}
.about {
  font-size: 24px;
  font-family: "FZYaoti";
  letter-spacing: 10px;
  color: #fff;
  font-weight: 900;
  // height: 100%;
  // padding: 20%;
}
.about p:first-child {
  color: red;
}

.jianjie-title {
  font-size: 30px;
  font-weight: 700;
  color: black;
  text-align: center;
  padding: 20px 50px;
}
// .jianjie-title:hover {
//   text-decoration: underline;
//   color: red;
//   cursor: pointer;
// }
.jianjie-msg {
  display: flex;
  align-items: flex-start;
  padding: 60px 200px;
  .img {
    padding: 20px 40px;
    width: 50%;

    height: 300px;
    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .msg {
    width: 50%;
    padding: 20px 40px;
    font-size: 14px;
    .msg-title {
      font-size: 22px;
      font-weight: 500;
      // width: 50%;
      border-bottom: 1px solid red;
    }
    p {
      // padding: 10px 20px;
      line-height: 24px;
      letter-spacing: 2px;
    }
  }
}
.jianjie .jianjie-msg:nth-child(2n-1) {
  flex-direction: row-reverse;
}
.saishi {
  font-size: 14px;
  color: #aaa;
}
.saishi-btn {
  color: red;
  // text-decoration: underline;
  // text-decoration-color: rgb(0, 81, 255);
  // background: red;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 10%;
  text-align: center;
}
.hide {
  height: 60px;
  overflow: hidden;
}
.show-hide {
  width: 100%;
  height: 20px;
  // background: #ccc;
  background-image: linear-gradient(#fff, #aaa);
  opacity: 0.5;
  margin-top: -25px;
}

.DHS {
  font-size: 180px;
  color: #ccc;
  position: absolute;
  z-index: -1;
  opacity: 0.3;
  // left: 10%;
  margin-top: -8%;
}
.OLYMPIC {
  font-size: 180px;
  color: #ccc;
  position: absolute;
  margin-top: -8%;
  margin-left: 10%;
  z-index: -1;
  opacity: 0.3;
}
.CHAMPION {
  font-size: 180px;
  color: #ccc;
  position: absolute;
  margin-top: -15%;
  // margin-left: 10%;
  left: 10%;
  z-index: -1;
  opacity: 0.3;
}
.HURRICANE {
  font-size: 180px;
  color: #ccc;
  position: absolute;
  left: 15%;
  margin-top: -4%;
  // margin-left: 10%;
  z-index: -1;
  opacity: 0.3;
}
.RAINBOW {
  font-size: 180px;
  color: #ccc;
  position: absolute;
  left: 15%;
  margin-top: 1%;
  // margin-left: 10%;
  z-index: -1;
  opacity: 0.3;
}
.BADMINTON {
  font-size: 180px;
  color: #ccc;
  position: absolute;
  left: 15%;
  margin-top: 1%;
  z-index: -1;
  opacity: 0.3;
}
.WEIGHTLIFTING {
  font-size: 180px;
  color: #ccc;
  position: absolute;
  left: 5%;
  margin-top: 1%;
  z-index: -1;
  opacity: 0.3;
}
.BIG-BALLSPORTS {
  font-size: 180px;
  color: #ccc;
  position: absolute;
  left: -24%;
  margin-top: 1%;
  z-index: -1;
  opacity: 0.3;
}
.DHS2 {
  font-size: 180px;
  color: #ccc;
  position: absolute;
  left: 0;
  // margin-top: 4%;

  z-index: -1;
  opacity: 0.3;
}

@media screen and (max-width: 1024px) {
  .DHS,
  .OLYMPIC,
  .CHAMPION,
  .HURRICANE,
  .RAINBOW,
  .BADMINTON,
  .WEIGHTLIFTING,
  .BIG-BALLSPORTS,
  .DHS2 {
    display: none;
  }
  .title {
    height: auto !important;
  }
  .jianjie-msg {
    flex-direction: column !important;
    padding: 1%;
    .msg {
      width: 90%;
      padding: 0px 5%;
    }
    .img {
      width: 90%;
      padding: 0px 5%;
    }
  }
  .msg-title {
    text-align: center;
    font-size: 20px;
  }
  .msg {
    font-size: 12px !important;
  }
}
</style>
